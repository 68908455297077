/* eslint-disable @typescript-eslint/dot-notation */
import { ShippingExtensions as Base } from 'core/extensions/ShippingExtensions'
import { DecodedItem, Shippings } from 'stylewhere/api'
import { FormSchemaData, ShippingOperationConfig } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { askUserConfirmation } from 'stylewhere/shared/utils'

export class ShippingExtensions extends Base {
  static async beforeConfirm<O extends any[]>(
    operation: ShippingOperationConfig,
    _data: FormSchemaData,
    objects: O,
  ): Promise<boolean> {
    if (operation.attributes['checkAlreadyPackedItemsCustomAction'] === 'true') {
      const alreadyPackedItems = (objects as DecodedItem[]).filter((itm) => itm.statuses?.includes('ALREADY_PACKED'))
      if (alreadyPackedItems.length === 0) return true
      const res = (await Shippings.executeAction(operation, 'retrieveSerialAndParcel', {
        attributes: {
          itemIds: alreadyPackedItems.map((itm) => itm.item?.id).join(';'),
        },
      })) as { serial: string; parcelCode: string }[]
      if (res.length === 0) return true
      let message = `${__(T.messages.are_you_sure_you_want_to_confirm)}\n`
      for (const item of res) {
        message += `${__(T.misc.serial)} ${item.serial} ${__(T.misc.already_in_parcel)} ${item.parcelCode}\n\n`
      }
      const ok = await askUserConfirmation(__(T.messages.warning_items_found), message)
      if (!ok) throw new Error(__(T.misc.operation_canceled_by_user))
    }
    return true
  }

  static itemIntrinsicQuantityField = 'item.attributes.quantity'
}
