import { T, __ } from 'stylewhere/shared/i18n'

export * from 'core/shared/utils'

export function getCustomErrorCodes(status: number | undefined): { [key: string]: string } {
  switch (status) {
    case 502:
      return {
        ['-1']: __(T.custom.GENERIC_ERROR),
        ['0']: __(T.custom.GENERIC_ERROR),
        ['001']: __(T.custom.ECERTIFICATE_WORKORDER_NOT_FOUND),
        ['002']: __(T.custom.ITEM_IN_ANOTHER_PARCEL),
        ['003']: __(T.custom.ECERTIFICATE_WORKORDER_CREATED_BOT_NOT_COMPLETED),
        // ["004"]:__(T.custom.ECERTIFICATE_WORKORDER_CREATED_BOT_COMPLETED),
        ['010']: __(T.custom.INVALID_TIMESTAMP),
        ['011']: __(T.custom.INVALID_SERIAL),
        ['012']: __(T.custom.INVALID_WORKORDER),
        ['013']: __(T.custom.INVALID_SKU),
        ['014']: __(T.custom.INVALID_SKU),
        ['015']: __(T.custom.INVALID_PARCEL_NUMBER),
        ['400-200']: __(T.custom.SAP_UCS_NOT_CONFIRMED),
        ['400-210']: __(T.custom.SAP_REMOVABLE_SYNCHRO_NOT_PERFORMED),
        ['400-220']: __(T.custom.SAP_INCORRECT_OPERATION_STATUS),
        ['400-300']: __(T.custom.SAP_COMPLETED_NOT_FOUND_IN_TNT),
        ['400-400']: __(T.custom.SAP_UCS_CANNOT_BE_COMPLETED),
        ['404-100']: __(T.custom.SAP_WORKORDER_CLOSED),
        ['500-990']: __(T.custom.SAP_INTERNAL_ERROR),
        ['500-999']: __(T.custom.SAP_INTERNAL_ERROR),
        ['504-999']: __(T.custom.SAP_TIMEOUT_BUT_OK_AGGREGATION),
        ['UCS_EXIST']: __(T.custom.LVSP_PARCEL_NUMBER_ALREADY_AGGREGATED),
        ['CONFLICT_SERIALNUMBER_NOT_EXISTES']: __(T.custom.LVSP_SERIAL_ALREADY_AGGREGATED),
        ['CONFLICT_QUANTITY']: __(T.custom.LVSP_MISMATCH_EXPECTED_QUANTITY),
        ['NOT_FOUND_SKU']: __(T.custom.LVSP_SKU_NOT_FOUND),
        ['NOT_FOUND_SUPPLIER']: __(T.custom.LVSP_SUPPLIER_NOT_FOUND),
        ['CONFLICT_FLAG']: __(T.custom.TECHNICAL_ERROR),
      }
    default:
      return {}
  }
}
