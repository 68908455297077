import { merge as _merge } from 'lodash'
import fr from 'core/translations/fr'

export default _merge(fr, {
  titles: {
    outbound_bthp: 'Certificat de sortie BTHP',
  },
  simple_parcel_list: {
    to_read: 'À lire',
    ok: 'Ok',
    force: 'Forcer',
    force_parcel: 'Forcer le colis {{code}}',
    force_parcel_desc: 'Insérez le code PIN utilisateur pour forcer le colis',
    forced: 'Forcé',
    unexpected: 'Inattendu',
    remove: 'Supprimer',
    invalid_pin: 'Code PIN utilisateur invalide',
  },
  create_parcel_gate: {
    width: 'Largeur',
    height: 'Hauteur',
    depth: 'Profondeur',
    tare: 'Tare',
    description: 'Description',
  },
  misc: {
    sorted_quantity: 'Qté ventilée',
    sorted_date: 'Date de ventilation',
    start_and_read: 'Veuillez démarrer les antennes et placer le produit',
    internal_tag: 'ÉTIQUETTE INTERNE',
    external_tag: 'ÉTIQUETTE EXTERNE',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Certains articles lus ne peuvent pas être pris en charge par le fournisseur.',
    QUANTITY_MISMATCH_ERROR: 'Les quantités saisies ne correspondent pas aux quantités détectées.',
    ZONE_MISMATCH_ERROR: 'La zone saisie ne correspond pas à la zone détectée.',
  },
  custom: {
    barcode: 'Code-barres BC',
    barcodeBCTrim: 'Code-barres BC trim',
    barcodeJDE: 'Code-barres JDE',
    barcodeROQ: 'Code-barres ROQ',
    barcodeROQTrim: 'Code-barres ROQ trim',
    class: 'Classe',
    department: 'Département',
    dimensionSystem: 'Classe personnalisée',
    ean: 'EAN',
    eanIdPadre: 'EAN parent',
    itemId: 'ID article',
    stagioneROQ: 'Saison ROQ',
    subclass: 'Sous-classe',
    subdepartment: 'Sous-département',
    variantType: 'Typologie',
    rfidEnabled: 'RFID activé',
    stato: 'Statut',
    ricondizionamentoFornitore: 'Reconditionnement fournisseur',
    codiceShelf: 'Compartiment de stockage',
    montante: 'Section',
    ripiano: 'Bac',
    scaffale: 'Rayon',
    model: 'Modèle',
    sizeCode: 'Code taille',
    sizeDescription: 'Description taille',
    serial: 'Numéro de série',
    identifier: 'Numéro de série',
    product: 'Code-barres BC',
    shift: 'Poste',
    destination: 'Destination',
    regDate: 'Date',
    listPosition: 'Position dans la liste',
    list: 'Liste',
    customerName: 'Nom du client',
    regTime: 'Heure',
    event: 'Événement',
    customer: 'Client',
    isCarryover: 'Report',
    carryoverValue: 'Report',
    madeIn: 'Fabriqué en',
    collectionCode: 'Collection',
    collectionDescription: 'Description de la collection',
    collectionYear: 'Année',
    fatherProductValue: 'Produit parent',
    genderValue: 'Genre',
    genderValueDescription: 'Description du genre',
    line: 'Marque',
    lineDescription: 'Description de la ligne',
    marchioDescription: 'Description du Marchio',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Catégorie',
    merchandiseClassDescription: 'Description de la catégorie',
    merchandiseSubclassCode: 'Sous-catégorie',
    merchandiseSubclassDescription: 'Description de la sous-catégorie',
    modelPartColor: 'Modèle Partie Couleur',
    modelPartColorSizeValue: 'Modèle Partie Couleur Taille',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Saisonnier',
    seasonalValue: 'Saisonnier',
    sleeveTypeValue: 'Type de manche',
    tooManyTags: 'Trop d’étiquettes',
    nfcDetectedPleaseUseProductControl:
      'Étiquette NFC détectée. Veuillez utiliser la fonctionnalité de contrôle produit.',
    GENERIC_ERROR: 'Erreur - Veuillez contacter le support via ContactIS',
    ECERTIFICATE_WORKORDER_NOT_FOUND: "Il n'y a pas de numéro d'OF lié à ce numéro UCS *{parcelCode} dans eCertificate",
    ITEM_IN_ANOTHER_PARCEL: 'Cet article est déjà déclaré dans un autre colis UCS *{parcelCode}',
    ECERTIFICATE_WORKORDER_CREATED_BOT_NOT_COMPLETED: "Le numéro d'OF dans eCertficate a été créé mais pas complété",
    INVALID_TIMESTAMP: 'Timestamp invalide',
    INVALID_SERIAL: 'Numéro de série invalide',
    INVALID_WORKORDER: 'Numéro d’OF invalide',
    INVALID_SKU: 'SKU invalide',
    INVALID_PARCEL_NUMBER: 'Numéro de colis UCS invalide',
    SAP_UCS_NOT_CONFIRMED: 'UCS non confirmé en fin de ligne',
    SAP_REMOVABLE_SYNCHRO_NOT_PERFORMED: 'Synchro amovible non effectuée',
    SAP_INCORRECT_OPERATION_STATUS: "Le status de l'opération de l'UCS *{parcelCode} n'est pas correct dans SAP ME",
    SAP_COMPLETED_NOT_FOUND_IN_TNT: 'UCS déjà complété dans SAP, non présent dans T&T',
    SAP_UCS_CANNOT_BE_COMPLETED: 'UCS *{parcelCode} ne peut pas être complété dans SAP ME/MII',
    SAP_WORKORDER_CLOSED: "L'OF associé à l'UCS *{parcelCode} est fermé dans SAP MII",
    SAP_INTERNAL_ERROR: 'Erreur technique dans SAP MII',
    SAP_TIMEOUT_BUT_OK_AGGREGATION: "Timeout -> L'agrégation est OK",
    LVSP_PARCEL_NUMBER_ALREADY_AGGREGATED: 'Numéro UCS déjà agrégé dans LVSP',
    LVSP_SERIAL_ALREADY_AGGREGATED: 'Article scanné déjà agrégé dans un précédent numéro UCS',
    LVSP_MISMATCH_EXPECTED_QUANTITY: 'Quantité scanné différente de la quantité attendue dans LVSP',
    LVSP_SKU_NOT_FOUND: 'SKU inconnu dans LVSP',
    LVSP_SUPPLIER_NOT_FOUND: 'Atelier fournisseur inconnu dans LVSP',
    TECHNICAL_ERROR: 'Erreur Technique - Contactez le support via ContactIS',
  },
  identifier_error: {
    WRONG_MADEIN: 'Mauvais MadeIn',
  },
})
