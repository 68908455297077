import Base from 'core/pages/Encoding/EncodingVerify'
import { TmrTag } from 'stylewhere/api'
import { EncodingExtensions } from 'stylewhere/extensions'
import { RfidReader, Router } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { getAutomaticRestartAntenna, showToastError, sleep } from 'stylewhere/shared/utils'

export default class EncodingVerify extends Base {
  isDecodeNfc = false

  startReader = async () => {
    try {
      await RfidReader.initialize()
      RfidReader.setOnTagReadCallback(this.onTagRead)
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      await RfidReader.start(undefined, () => {}, undefined, getAutomaticRestartAntenna(this.operation))
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), this.isModal)
    }
  }

  clearReads = async () => {
    this.stopTimer()
    RfidReader.clear()
    if (!RfidReader.isReading()) {
      await RfidReader.start(undefined, () => {}, undefined, getAutomaticRestartAntenna(this.operation))
    }
    this.setState({
      encodingValidation: { operationId: this.operation.id, identifiers: [] },
      encodingValidationResponse: undefined,
    })
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, () => {}, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  onTagRead = async (tag: TmrTag) => {
    await sleep(Math.random() * 500)

    const { encodingValidation } = this.state

    //se leggo tag e ho nfc con decode in corso scarto tag e lo rimuovo dal reader
    if (tag.epc && this.isDecodeNfc) {
      RfidReader.removeTags([tag.epc])
      return
    }
    if (tag.uid && this.isDecodeNfc) {
      RfidReader.removeTags([tag.uid])
      return
    }

    try {
      this.isDecodeNfc = true
      this.stopTimer()
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      const nfcCollision = encodingValidation?.identifiers.some(
        (id) => id.identifierType === 'NFC_TAG' && id._status === 'NFC_COLLISION',
      )
      this.setState({ encodingValidation, processing: nfcCollision ? false : this.state.processing }, () => {
        if (nfcCollision) {
          this.isDecodeNfc = false
          console.log('NFC collision detected')
          RfidReader.stop()
        } else {
          this.isDecodeNfc = false
          this.onTagReadTimer()
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.isDecodeNfc = false
    }
  }

  _changeTab = async () => {
    let originalTemplatePath = ''
    const { originTemplatePath } = this.state
    if (!originTemplatePath) {
      originalTemplatePath = localStorage.getItem('originTemplatePath') || ''
    } else {
      originalTemplatePath = originTemplatePath
    }
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(`/encoding/${originalTemplatePath ? `${originalTemplatePath}/` : ''}:opCode` as any, {
        opCode: this.operation.code,
      })
      localStorage.removeItem('originTemplatePath')
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }
}
