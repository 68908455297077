import Base from 'core/pages/Shipping/ShippingStart'
import { OperationReadingProps, Router } from 'stylewhere/shared'

export default class ShippingStart extends Base<OperationReadingProps> {
  singleShippingScreen =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['singleShippingScreen'] && this.operation.attributes['singleShippingScreen'] === 'true'

  async componentDidMount() {
    if (this.singleShippingScreen) {
      Router.navigate(this.submitPath, { opCode: this.operation.code })
    }
  }

  public render() {
    return this.singleShippingScreen ? <div /> : super.render()
  }
}
